<template>
  <div class="redBraceletH5">
    <van-image
      class="redBraceletH5_banner"
      width="100%"
      src="https://cdn-zettayotta-platform-static.medical-force.cn/zettayotta_platform/20230619/b38f649aad9b40d69bfdf81c68b73d3c.jpg"
    />

    <van-tabs v-model="activeName" color="#DD0E18" sticky>
      <van-tab v-for="(img,index) in imgList" :key="index" :title="img.title" :name="img.name" class="active_tab">
        <van-image
          width="100%"
          :src=img.imgUrl
        />
      </van-tab>
    </van-tabs>
    <draggable-resizable
      v-if="showRankingList"
      @click.native="jumpRanking"
      class="redBraceletH5_drag"
      style="height: 5rem; width: 5rem"
      :bounds="'parent'"
      ref="draggable"
      :z="999999"
      :isDraggable="true"
      :isResizable="{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: true,
        bottomRight: true,
        bottomLeft: true,
        topLeft: true,
      }"
    >
      <img
        class="drag_img"
        src="https://cdn-zettayotta-platform-static.medical-force.cn/zettayotta_platform/20230601/ad6f0e15ac8f4cafbaae1b51c8de3c51.png"
      />
    </draggable-resizable>
  </div>
</template>
<script>
import Vue from 'vue';
import DraggableResizable from 'vue-draggable-resizable'

export default {
  components: { DraggableResizable },
  data () {
    return {
      activeName: 'a',
      imgList:[
        {
          title:'学会通知',
          imgUrl:"https://cdn-zettayotta-platform-static.medical-force.cn/zettayotta_platform/20230620/a8f8c04595d8404280172ecfa3af059a.jpg",
          name:'a'
        },
        {
          title:'大赛方案',
          imgUrl:"https://cdn-zettayotta-platform-static.medical-force.cn/zettayotta_platform/20230619/8605fa8f3b2b41f09decc5b95b33ee90.jpg",
          name:'b'
        },
        {
          title:'参与流程',
          imgUrl:"https://cdn-zettayotta-platform-static.medical-force.cn/zettayotta_platform/20230620/7a6ded68ba724e9db8913cf8fab5cbf1.jpg",
          name:'c'
        },

      ],
      showRankingList:false
    };
  },
  name: 'redBraceletH5',
  computed: {
  },
  mounted () {
    // 防止在拖动时页面滚动
    const layer = this.$refs.draggable.$el;
    layer.addEventListener('touchmove', (event) => {
      event.preventDefault();
    }, {
      passive: false
    });
  },
  methods: {
    jumpRanking () {
      this.$router.push({
        path: '/redBraceletH5/rankingList',
      })
    },

  }
}
</script>
<style lang="less">
.redBraceletH5 {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background-color: #ffffff;
  .redBraceletH5_banner {
    height: 16rem;
  }
  .active_tab {
  }
  .redBraceletH5_drag {
    bottom: 0;
    position: fixed;
    right: 0;
    bottom: 8rem;
    cursor: pointer;
    .drag_img {
      width: 5rem;
      height: 5rem;
    }
  }
}
</style>
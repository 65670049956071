var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"redBraceletH5"},[_c('van-image',{staticClass:"redBraceletH5_banner",attrs:{"width":"100%","src":"https://cdn-zettayotta-platform-static.medical-force.cn/zettayotta_platform/20230619/b38f649aad9b40d69bfdf81c68b73d3c.jpg"}}),_c('van-tabs',{attrs:{"color":"#DD0E18","sticky":""},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.imgList),function(img,index){return _c('van-tab',{key:index,staticClass:"active_tab",attrs:{"title":img.title,"name":img.name}},[_c('van-image',{attrs:{"width":"100%","src":img.imgUrl}})],1)}),1),(_vm.showRankingList)?_c('draggable-resizable',{ref:"draggable",staticClass:"redBraceletH5_drag",staticStyle:{"height":"5rem","width":"5rem"},attrs:{"bounds":'parent',"z":999999,"isDraggable":true,"isResizable":{
      top: false,
      right: false,
      bottom: false,
      left: false,
      topRight: true,
      bottomRight: true,
      bottomLeft: true,
      topLeft: true,
    }},nativeOn:{"click":function($event){return _vm.jumpRanking.apply(null, arguments)}}},[_c('img',{staticClass:"drag_img",attrs:{"src":"https://cdn-zettayotta-platform-static.medical-force.cn/zettayotta_platform/20230601/ad6f0e15ac8f4cafbaae1b51c8de3c51.png"}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }